import './home.css';
import { useEffect, useState } from "react"
import 'material-symbols/rounded.css';

const addSecondsToCurrentTime = (secondsToAdd) => {
    let currentTime = new Date();
    currentTime.setSeconds(currentTime.getSeconds() + secondsToAdd);
    return currentTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
};

const beautifyDate = (seconds) => {
    return new Date(seconds * 1000).toLocaleString('fr-FR', { day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' }).replace(" ", " à ").replace(":", "h");
};

async function getTorrentsInfos() {
    try {
        const res = await fetch("https://qback.qor.us:25600/qbit")
        const body = await res.json()
        const ret = []

	if (body.forEach) {
            body.forEach(x => {
            	ret.push(Entry({
                	title: x.name,
                	start: x.added_on,
                	dlSpeed: x.dlspeed,
                	progress: x.progress,
                	end: x.eta
                }))
            })
	}
	console.log("Data received : " + ret)
        return ret
    } catch (err) {
        console.error("Failed to fetch : " + err)
        return []
    }
    
}

function Entry({title, start, dlSpeed, progress, end}) {   
    const progressTimeHundred = progress * 100;
    const dlSpeedInMbps = dlSpeed / 1000000

    if (end === 8640000) {
        end = "En pause"
    } else {
        end = "Fin à " + addSecondsToCurrentTime(end)
    }
    
    return (
        <>
            <div id="w-node-_33fbd135-4573-9379-1d02-9152eccb893a-2ab9ae95" class="card">
                <h3 id="w-node-_37c2c0d0-b4ab-512a-6041-dfde9b2edb1b-2ab9ae95" class="card-heading">{title}</h3>
                <div class="card-info">Ajouté le {beautifyDate(start)} - Vitesse téléchargement : {dlSpeedInMbps.toFixed(2)} Mbps</div>
                <div class="progress-bar-wrapper">
                    <div class="progress-bar-info">{progressTimeHundred.toFixed(1)}%</div>
                    <div class="progress-bar-track">
                        <div class="progress-bar" style={{ width: `${progressTimeHundred}%` }}></div>
                    </div>
                    <div class="progress-bar-info">{end}<br/></div>
                </div>
            </div>
        </>
    )
}

const Home = () => {
    const [torrents, setTorrents] = useState(null)
    useEffect(() => {
        const update = async () => {
            getTorrentsInfos()
            .then(res => setTorrents(res))
            .catch(err => {
                setTorrents([])
                console.log(err)
            })
        }

        update()
        setInterval(update, 1000);
    }, [])
    return (
        <>
            <html data-wf-domain="qor.us">
            <head>
                <meta charset="utf-8" />
                <title>Qorus</title>
                <meta content="width=device-width, initial-scale=1" name="viewport" />
            </head>

            <body class="body">
                <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
                    <div class="navbar-container w-container">
                        <h1 class="brand">QORUS</h1>
                        <nav role="navigation" class="nav-menu w-nav-menu">
                            <a href="#" class="nav-link w-nav-link">Accueil</a>
                            <a href="#" class="nav-link w-nav-link">Admin</a>
                        </nav>
                        <div class="w-nav-button">
                            <div class="w-icon-nav-menu"></div>
                        </div>
                    </div>
                </div>
                <section class="section">
                    <div class="w-layout-blockcontainer cards-container w-container">
                        <div class="cards-block">
                            {torrents}
                        </div>
                    </div>
                </section>
                <section class="footer">
                    <div class="w-layout-blockcontainer container w-container"></div>
                </section>
            </body>
            </html>
        </>
    );
};

export default Home;
